.gff-carousel-bloques__native-container-bloques-centro-4 {

    .cmp-container {
        .aem-Grid {
            display: flex;
            justify-content: space-around;
        }

        .gff-carousel-bloques__native-item {
            width: 254px !important;

            .image {
                .cmp-image {
                    .cmp-image__image {
                        width: 80px;
                        margin: 0 auto;
                    }

                }

            }

            .text {
                .cmp-text {
                    p {
                        margin: 10px;
                        font-weight: bold;
                        color: $Tonal-range-Neutral-neutral30;
                        line-height: 24px;
                    }
                }
            }

            &.gff-cmp-cont-borde-gris {
                .cmp-container {
                    border-radius: 16px;
                }
                
            }
        }
    }

    

}


.gff-carousel-bloques__native-container-bloques-dos-filas {

}

/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
        
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
    .gff-carrusel-bloques__component {
        .gff-carrusel-bloques__content {
            width: 90%;
            margin: 0 auto;
        }
    }

    
.gff-carousel-bloques__native-container-bloques-centro-4 {

    .cmp-container {

        .aem-Grid {
            
            justify-content: flex-start;
        }
        .gff-carousel-bloques__native-item {
            width: 200px !important;
            .cmp-container {
                height: 210px;
            }
            
        }
    }

    

}
}


/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  
  
}

