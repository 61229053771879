.gff-cmp-cont-banner-color {

    background: linear-gradient(to right, #24B8EF 0%, #0058CB 57%, #351782 100%);

    .gff-cmp-cont-body {

        .cmp-container{

            .aem-Grid {
                display: flex;
                flex-wrap: nowrap;
            }

            .gff-contenedor-flex {

                display: flex;
                flex-direction: column;
                justify-content: center;

                .cmp-container {
                    
                    .aem-Grid {

                    
                        .text {
                            .cmp-text{
                                p {
                                    font-size: $dosxl;
                                    display: flex;
                                    margin: 0;
                                    text-align: center;
                
                                    a {
                                        font-size: $sm;
                                        color: $Tonal-range-Primary-primary40;
                                    }
                                }
                            }
                        }
                
                    
                
                        
                    }
            }
        }
        .gff-cmp-cont-descargas-boton {
                    
        
            .cmp-container {
                border: none;
                margin-left: 50px;

                .aem-Grid {
                    background-color: #ffffff;

                    .text {
                        .cmp-text{
                            p{
                                text-align: center;
                                a{
                                    &::after {
                                        content: url('/src/resources/images/arrow-outward-azul.png');
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        }

        
    
    }
}   

    /* Para pantallas de escritorio con un ancho mínimo de 1360px */
    @media (min-width: 1360px) {
        
    }
    
    
    /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
    @media (min-width: 768px) and (max-width: 1359px) {

        .gff-cmp-cont-banner-color{
            .cmp-container{
                .aem-Grid{
                    .gff-cmp-cont-body{
                        .cmp-container{
                            .aem-Grid{
                                .gff-contenedor-flex{
                                    .cmp-container{
                                        .aem-Grid{
                                            .gff-texto-blanco{
                                                .cmp-text{
                                                    p{
                                                        text-align: left;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .gff-cmp-cont-descargas-boton{
                                    .cmp-container{
                                        .aem-Grid{
                                            width: 100%;
                                            padding: 0;
                                            min-height: 48px;

                                            .text {
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    }
    
    /* Para dispositivos móviles con un ancho máximo de 767px */
    @media (max-width: 767px) {

        

        .gff-cmp-cont-banner-color {

           
        
            .gff-cmp-cont-body {
        
                .cmp-container{
        
                    .aem-Grid {
                        
                        flex-wrap: wrap;

                        .gff-cmp-cont-descargas-boton {
                            width: 100%;

                            .cmp-container {
                                margin-left: 0;

                                .aem-Grid {
                                    width: 100%;
                                    flex-wrap: nowrap;
                                    padding: 0;
                                    min-height: 48px;

                                    .text, .image {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                    }

                                    
                                }
                            }
                        }

                        .gff-contenedor-flex {
                            width: 100%;
                            .cmp-container{
                                .aem-Grid {
                                    .gff-texto-blanco{
                                        margin-top: 24px;
                                        .cmp-text{
                                            p{
                                                text-align: left;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

      
      
    }
}
}
}
