
.gff-cmp-txt-body-card {


    p {
        line-height: 24px;
    color: $Tonal-range-Neutral-neutral30;
    font-size: $xs;
    } 

    ul {

        padding-left: 12px;

        li {

            padding-left: 10px;
            font-size: $xs;
            color: $Tonal-range-Neutral-neutral30;

            &::marker {
                content: url(./resources/images/check-azul.png);
                }
        }
        
        
         
    }
}



/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
    .gff-cmp-txt-body-card {

        height: 260px;
        
    }

    .gff-cmp-txt-body-card.gff-cmp-txt-small {
        height: 80px;
        .cmp-text {
            p {
                font-size: $sm;
            }
        }
    }
  }
  
  /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
  @media (min-width: 768px) and (max-width: 1359px) {
    .gff-cmp-txt-body-card {

        height: 260px;
        
    }
  }
  
  
  /* Para dispositivos móviles con un ancho máximo de 767px */
  @media (max-width: 767px) {
    /* Estilos específicos para dispositivos móviles */
  }