/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1025px) {
.gff-banner-hero-carrusel {
    .cmp-gff-banner_hero_carrusel-component{
        .cmp-gff-banner_hero_carrusel-slider{
            .cmp-gff-banner_hero_carrusel-slider-banners{
                background-size: cover !important;
                height: 483px;
                background-position: 50%;

                .cmp-gff-banner_hero_carrusel-slider-banners_content {
                    h2{
                        letter-spacing: -2px;
                    }
                }
            }
        }
    }
    .cmp-gff-banner_hero_carrusel-component {
        justify-content: center;
        display: flex;
    }

    .cmp-gff-banner_hero_carrusel-slider-arrows {
        width: 90%;
        top: 46%;
    }    

    .cmp-gff-banner_hero_carrusel-slider {
        width: 100%;
    }    

    .cmp-gff-banner_hero_carrusel-slider-banners-aux {
        max-width: 1120px;
        margin: 0 auto;
    }

    .cmp-gff-banner_hero_carrusel-slider-banners_tag, .cmp-gff-banner_hero_carrusel-slider-banners_content, .cmp-gff-banner_hero_carrusel-slider-banners_buttons {
        margin-left: 0;
    }

    }
}


@media (max-width: 767px) {
    .cmp-gff-banner_hero_carrusel-slider-banners {
        .cmp-gff-banner_hero_carrusel-slider-banners_content{
            width: 95%;
        }
    }
}