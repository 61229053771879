.gff-cards-country {
    .gff-card-country-general {
        max-width: 1120px;
        .gff-card_country-component{   
            a{
                .gff-card_country-component_text-img{
                    h6 {
                        &.gff-card_country-component_text-title{
                            font-size: $xs;
                            color: $Tonal-range-Neutral-neutral10;
                        }
                    }
                }
            }

        }
}
}

/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1024px) {
    .gff-cards-country {
        .gff-card-country-general {
            justify-content: center;
        }
    }
}

@media (min-width: 328px) and (max-width: 767px) {
    .gff-cards-country{
        .gff-card-country-general{
            min-width: 328px;
        }
    }
}