/* List Item con imagen a la izquierda , texto en medio y botón al final */
.gff-contenedor-flex.gff-cmp-list-item-img{
    .cmp-container {
        .aem-Grid {
            display: flex;
            justify-content: center;
        }


    }
    
    .gff-cmp-list-item-img {
        max-width: 1120px;
        .cmp-container {
            border-radius: 8px;

            .aem-Grid {

                .text {
                    .cmp-text {
                        p{
                            font-size: $sm;
                                color: $Tonal-range-Neutral-neutral10;
                            b{
                                font-size: $md;
                                color: $Tonal-range-Primary-primary30;
                            }
                        }
                    }
                }

                .image {
                    .cmp-image {
                        margin: 21px 0 21px 16px;
                        img {
                            width: 48px;

                        }
                    }
                }

                .gff-cmp-cont-descargas-boton {
                    .cmp-container {
                        .aem-Grid {
                            min-height: 28px;
                            min-width: 170px;

                            .text {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }

                            .image {
                                .cmp-image {
                                    margin: 0;
                                    img {
                                        width: 18px;
            
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }



    }
}


/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
        
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
  
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  
  
}