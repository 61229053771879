.gff-cmp-card-seguros-simple-no-icon{

}

.gff-cmp-card-seguros-simple-icon {
    .gff-card_seguros{
        .gff-card_seguros-header{
            background-color: #fff;
            justify-content: flex-start;
            gap: 20px;
            padding: 16px 32px;

            .gff-card_seguros-header-text{
                .gff-card_seguros-header-text_subtitle{
                    display: none;
                }

            }
            .gff-card_seguros-header-value{
                display: none;
            }
        }
        .gff-card_seguros-body{
            .gff-card_seguros-body-content{
                p{
                    padding-left: 16px;
            }
        }
    }
    
}
}

.gff-cmp-card-seguros-simple-no-button {
    .gff-card_seguros-body-button {
        display: none;
    }
}