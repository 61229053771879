.gffaem-cmp-cont-product-detail {
    background: $color-background;
    border-radius: 24px;
    overflow: hidden;
    max-width: 336px;
    filter: drop-shadow(0px 1px 3px #00000070);

    .cmp-image {
        margin-top: 0;
    }

    & > .cmp-container > .container{
        padding: 16px;

    }

    .gffaem-cmp-cuerpo-card {
        position: relative;
    }
}



.gffaem-cmp-cont-product-detail.gffaem-card-xl{
    max-width: 636px;
}
.gffaem-cmp-cont-product-detail.gffaem-card-s{
    max-width: 286px;
}

// CARD HORIZONTAL

.gffaem-card-horizontal{
    max-width: 928px;
    & > .cmp-container {
        display: inline-flex;
        height: 294px;

        .gffaem-cmp-cuerpo-card {
            padding: 0 16px;
            .cmp-container {
                .gff-cmp-txt-body-card {
                    height: 122px;
                }
            }
        }

       
    }
}

  /* Para dispositivos móviles con un ancho máximo de 767px */
  @media (max-width: 767px) {

    .gffaem-card-horizontal{
        max-width: 928px;
        & > .cmp-container {
            display: inline;
                flex-direction: column;
                height: auto; 

                .image {
                    .cmp-image {
                        img {
                            object-fit: cover;
                            height: 190px;
                        }
                    }
                }
           
        }
    }
    
        
  }

.gffaem-card-horizontal.gffaem-card-s{
    max-width: 864px;
}

.gffaem-card-horizontal {
    .gffaem-cmp-cuerpo-card {
        .cmp-container{
            display: inline-block;
            text-align: left;
        }
    }

    .gffaem-cmp-cont-button-cont {
        .cmp-container {
            display: flex;
            gap: 16px;
        }
    }

    .gff-cmp-txt-tagline-card {
        .cmp-text {
            text-align: left;
        }
    }
}