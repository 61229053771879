.cmp-teaser__imagen-texto-horizontal {
    .cmp-teaser {
        display: flex;
        min-height: 50px;
        align-items: center;
       
        .cmp-teaser__content {
           
            position: relative;
            top: 0;
            left: 15%;
            transform: none;
            .cmp-teaser__title, .cmp-teaser__pretitle {
                color: black;
            }
            

            .cmp-teaser__description {
                text-align: left;

                h1, h2, h3, h4, h5, h6 {
                    a{
                        color: gray;
                    }
                }

                p {
                    line-height: 1.08;
                    color: black;

                    b{
                        font-weight: 600;
                    }
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}

@media (min-width: $breakpoint-tablet) {
.cmp-teaser__imagen-texto-horizontal {
    .cmp-teaser {
        display: flex;
        min-height: 50px;
        align-items: center;
       
        .cmp-teaser__content {
            
            position: relative;
            top: 0;
            left: 15%;
            transform: none;

            .cmp-teaser__description {
                text-align: left;

                p {
                    line-height: 1.08;
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}
}