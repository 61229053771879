.gff-cmp-cont-features {
    &>.cmp-container {
        &>.aem-Grid {

            display: flex;
            flex-direction: row;

            .gff-contenedor-flex {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

        &>div.container {
            width: 33%;
        }
    }
}

    .gff-contenedor-flex {

        .cmp-container {
            .aem-Grid {

                .title {
                    .cmp-title {
                        h4 {
                            font-size: $dosxl;
                            font-weight: 200;
                            line-height: 34px;
                        }
                    }
                }
                .container {

                    max-width: 328px;

                    .cmp-container {
                        .aem-Grid {
                            display: flex;
                            justify-content: start;
                            height: 87px;

                            div.image {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }

                            div.text {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: 16px;
                            }

                            div.image:first-child {
                                width: 52px;
                                .cmp-image {
                                    margin: 0;
    
                                    img {
                                        width: 52px;
                                    }
                                }

                            }

                            div.image:last-child {
                                width: 32px;
                                .cmp-image {
                                    margin: 0;
    
                                    img {
                                        width: 32px;
                                    }
                                }

                            }

                            
                        } 
            }
        }
    }
}
}
}

/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
        
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
  
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  
  
}