.gff-hero-cont {

  position: relative;

      .title {
        h1{
          line-height: 30px !important
        }
        
      }

    .text {
        line-height: 24px !important;
      } 
      .gff-cmp-cont-body {
      &>.cmp-container {
        max-width: $body-desktop;
        margin: 0 auto;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  }

  .gff-hero-cont {
    .gff-cmp-cont-body {
      &>.cmp-container {        
        .title, .text {
          max-width: 215px;
          line-height: 24px;
        }
      }
    }
}
  
  }




  .gff-cont-banner-img {

    .cmp-image {
      margin-top: 0;

      .cmp-image__image {
        width: auto;
        height: 286px;
    }
    }
  }

.gff-cmp-banner-interno {
  // max-width: 1120px;
  height: 286px;
  margin: 0 auto;
  .cmp-container {

    border-radius: 16px;
    margin-top: 20px;

    .cmp-button {
      border-radius: 8px;
      font-weight: bold;
      text-decoration: none;
      margin: 0;
    }

    .cmp-title {
      margin-bottom: 8px;
    }
    .gff-texto-blanco {
        .cmp-text {
          p {
            margin-top: 0;
            margin-bottom: 22px;
          }
        }
      }
  
  }
}

  @media (min-width: 1360px) {
    /* Estilos específicos para pantallas de escritorio */
  }
  
  /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
  @media (min-width: 768px) and (max-width: 1359px) {
    .gff-hero-cont {
      &>.cmp-container {
        &>.aem-Grid {
          display: inline-block !important;
        }
      }
      .gff-cmp-cont-body {
        &>.cmp-container {
          height: 295px;
          padding-left: 32px;

          .gff-cmp-txt-tagline-card, .title, .text {
            max-width: 360px;
            line-height: 24px;
          }
        }
      }
  }
  .gff-cont-banner-img {

    width: auto !important;
    margin-left: 0 !important;

    .cmp-image {
      margin-top: 0;

      .cmp-image__image {
        height: 295px;
    }
    }
  }
}
  
  /* Para dispositivos móviles con un ancho máximo de 767px */
  @media (max-width: 767px) {
    .gff-hero-cont {
      &>.cmp-container {
        &>.aem-Grid {
          display: inline-block !important;
        }
      }
      .gff-cmp-cont-body {
        max-width: 200px;
        &>.cmp-container {
          
          height: 256px;
          padding-left: 16px;

          .gff-cmp-txt-tagline-card, .title, .text {
            max-width: 215px;
            line-height: 24px;
          }
        }
      }
  }
  .gff-cont-banner-img {

    width: auto !important;
    margin-left: 0 !important;

    .cmp-image {
      margin-top: 0;

      .cmp-image__image {
        height: 256px;
    }
    }
  }
  }