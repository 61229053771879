/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1120px) {


    .gff-filtro-independiente__content-tabs {
        &>.cmp-container{
            &>.aem-Grid{
                display: flex;
                flex-direction: column;
            }
        }
    }
    .gff-filtro-independiente__content-tabs.gffaem-cmp-cont-3-col{
        &.gffaem-cmp-cont-3-col {
            .cmp-container{
                .aem-Grid {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 16px;
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    margin: 0 auto;

                    .container.responsivegrid.gff-cmp-cont-borde-gris {
                        width: 100%;

                        .cmp-container {
                            border-radius: 16px;
                        }
                    }

                    .gff-cmp-cont-borde-gris {
                        .cmp-container {
                            .aem-Grid {
                                .gff-cmp-txt-body-card{
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
        
        
 }
 
 /* Para dispositivos móviles con un ancho máximo de 767px */
 @media (max-width: 767px) {

    .gff-filtro-independiente__content-tabs.gffaem-cmp-cont-3-col{

        

        &.gffaem-cmp-cont-3-col {
            .cmp-container{
                .aem-Grid {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 16px;
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    margin: 10px auto;

                    .container.responsivegrid.gff-cmp-cont-borde-gris {
                        width: 100%;

                        .cmp-container {
                            border-radius: 16px;
                        }
                    }
                }
            }
        }
    }

    .gff-filtro-independiente__content-tabs{
        .gff-cmp-cont-body.gff-cmp-cont-aviso{
            margin: 0 auto !important;
        }
}
        
        
 }
