.gff-cmp-cont-aviso {
&>.cmp-container {
    border-radius: 4px;
    overflow: hidden;
    &>.aem-Grid {
        .container {
            width: 40px;

            .cmp-container {
                padding: 8px;
                height: 37px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .image {
                    .cmp-image {
                        margin: 0;
                    }
                
                }

                
            }
        }
        .text {
            .cmp-text {
                height: 53px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 8px;

                p {
                    font-size: $xs;
                    color: $Tonal-range-Info-info20;
                    line-height: 20px;
                }
            }
        }
    }
}
}

/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
        
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
  
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  
  
}