.gff-card-gestion {
    .gff-card_gestion-component{
        .gff-card_gestion-component_img{
            img {
                width: 48px;
            }
        }
    }
}


.gff-cmp-cards-gestiones-azul-oscuro {
    .gff-card_gestion-component{
        background-color: #D9E9FF;
        padding: 20px;

        .gff-card_gestion-component_button{
            height: 44px;
            letter-spacing: -0.5px;
        }

        .gff-card_gestion-component_img {
            padding-right: 32px;
        }

        .gff-card_gestion-component_text{
            p{
                &.gff-card_gestion-component_text-title{
                    line-height: 26px;
                    font-size: $md;
                }
                &.gff-card_gestion-component_text-description{
                    line-height: 24px;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .gff-cmp-cards-gestiones-azul-oscuro .gff-card_gestion-component{
       
    }
}