.gffaem-cmp-cont-button-cont {
    
    .cmp-container {
        .button {
            margin: 8px 0;

            a{
                text-decoration: none;
                border-radius: 8px;
            }
          }
        .cmp-button {
            margin: 0;
            padding: 0;
            width: 100%;
            min-width: 156px;
            height: 48px;
            display: flex; /* Enables flexbox layout */
            align-items: center; /* Aligns child elements vertically */
            justify-content: center; /* Aligns child elements horizontally (optional for centering text) */

            .cmp-button__text {
                font-weight: bold;
                font-size: $sm;
            }
            
        }
    }
}


.gff-cmp-btn-azul-filled-base {
    
    .cmp-button {
        background: $Tonal-range-Primary-primary30;
        border: transparent;
        color: $Tonal-range-Primary-primary100;
    }

    &:hover {
        .cmp-button {
            background: rgba(0, 66, 156, 0.92);
        } 
    }
}

.gff-cmp-btn-white-text-base {

    .cmp-button {
        background: $Tonal-range-Primary-primary100;
        border: transparent;
        color: $Tonal-range-Primary-primary30;
    }

    &:hover {
        .cmp-button {
            background: rgba(0, 111, 253, 0.08);
        } 
    }
} 

.gff-cmp-btn-azul-outlined-base {
    
    .cmp-button {
        background: $Tonal-range-Primary-primary100;
        border: 2px solid $Tonal-range-Primary-primary50 ;
        color: $Tonal-range-Primary-primary30;
    }

    &:hover {
        .cmp-button {
            background: rgba(0, 111, 253, 0.08);
        } 
    }
}

.gff-cmp-btn-elevated-base {
    
    .cmp-button {
        background: $Tonal-range-Primary-primary100;
        border: transparent ;
        color: $Tonal-range-Primary-primary30;
        filter: drop-shadow(0px 1px 3px #00000070);
    }

    &:hover {
        .cmp-button {
            background: #EBF3FF;
        } 
    }
}