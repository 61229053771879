.gff-cmp-txt-tagline-card {
    

    .cmp-text {
        // text-align: center;
        margin: 0;
    }

    p {
        font-size: $xxs;
        padding: 0 15px 0 15px;
        display: inline-block;
        background: $Tonal-range-Neutral-neutral99;
        border-radius: 8px;
        color: $Tonal-range-Neutral-neutral30
    }
}

.gff-cmp-txt-tagline-card-white {
    p {
        background: $Tonal-range-Neutral-neutral100;
        color: $Tonal-range-Neutral-neutral30;
    }
}
.gff-cmp-txt-tagline-card-up {
    p {
        text-transform: uppercase;
    }
}