


/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
        
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
    .gff-lista_carrusel-component-carousel_content_slides {
        div{
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

    .gff-lista_carrusel-component-carousel_content_slides {
        div{
            img{
                width: 100%;
                height: auto;
            }
        }
    }
  
}