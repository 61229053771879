.gff-cmp-cont-texto-imagen {

    .image {
        .cmp-image__image {
            border-radius: 17px;
        }
    }
    
    .gff-cmp-title-seccion {
        .cmp-title {
            margin-bottom: 0;

            h3 {
                font-size: $dosxl;
                line-height: 34px;
                color: $Tonal-range-Neutral-neutral10;
            }
        }
    }

    ol {

        counter-reset: item;
        list-style: none;
        padding-left: 30px;
        margin: 0;

        li {
            position: relative;
            counter-increment: item;
            margin-bottom: 5px;
            color: $Pr-brand-Fill-Primary;
            line-height: 20px;
            font-size: $xs;
            padding-bottom: 33px;
            border-bottom: #B3BDD1 1px solid;
            margin-top: 24px;

            &:last-child {
                border-bottom: none;
            }

            b {
                font-size: $sm;
                line-height: 24px;
            }

            a {
                text-decoration: none;
                line-height: 18px;
                font-size: $xs;
                color: $Tonal-range-Primary-primary30;
                font-weight: 800;

                &::after {
                    content: '';
                    display: inline-block;
                    background-image: url(./resources/images/flecha-enlace-externo.png); /* Reemplaza con la ruta de tu imagen */
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 11px; /* Ajusta el tamaño según sea necesario */
                    height: 11px; /* Ajusta el tamaño según sea necesario */
                    margin-left: 8.5px;
                    vertical-align: middle;
                }
            }

             &::before {
                content: counter(item) ")";
                margin-right: 16px;
                font-weight: bold; 
                position: absolute;
                left: -30px;
                top: 4px;
                font-family: $font-family;
                font-size: $sm;
                font-weight: 200;
            } 
        }
    }
}

/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px){

    .gff-cmp-cont-texto-imagen {
        .gff-cmp-title-seccion, .text {
            width: 100% !important;
        }
        &>.cmp-container {
            &>.aem-Grid {
                &>.gff-cmp-cont-body{
                    &>.cmp-container{
                        &>.aem-Grid{
                            display: flex;
                            flex-direction: column-reverse;
                            &>.container {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

}

   /* Para dispositivos móviles con un ancho máximo de 767px */
   @media (max-width: 767px) {
    
    .gff-cmp-cont-texto-imagen {
        .gff-cmp-title-seccion, .text {
            width: 100% !important;
        }
        &>.cmp-container {
            &>.aem-Grid {
                &>.gff-cmp-cont-body{
                    &>.cmp-container{
                        &>.aem-Grid{
                            display: flex;
                            flex-direction: column-reverse;
                            &>.container {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

}