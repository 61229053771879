.gff-cmp-cont-descargas {
    &>.cmp-container {
        max-width: 928px;
        border-bottom: $Tonal-range-Neutral-neutral80 1px solid;
        &>.aem-Grid {
            display: flex;
            justify-content: space-between;
            .container {
                width: 40px;
    
                .cmp-container {
                    padding: 8px;
                    height: 37px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
    
                    .image {
                        .cmp-image {
                            margin: 0;

                            img {
                                width: 32px;
                            }
                        }
                    
                    }
    
                    
                }
            }
            .text {
                width: inherit;

                .cmp-text {
                    height: 53px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 8px;
    
                    p {
                        font-size: $sm;
                        color: $Tonal-range-Neutral-neutral10;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    }

    .gff-cmp-cont-descargas-boton {
        margin: 18px 0;
        .cmp-container {
            
            .aem-Grid {
                width: 50%;
                display: flex;
                justify-content: space-between;
                border: $Tonal-range-Primary-primary50 2px solid;
                padding: 8px;
                border-radius: 8px;
            }
        }
        .image {
            width: auto !important;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            .cmp-image {
                margin-top: 0;

                img {
                    width: 18px;
                }
            }
        }

        .text {
            .cmp-text {
                p, a {
                    margin: 0;
                    color: $Tonal-range-Primary-primary30;
                    font-size: $xs;
                    line-height: 20px;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }

        
    }
    
    /* Para pantallas de escritorio con un ancho mínimo de 1360px */
    @media (min-width: 1360px) {
            
    }
    
    
    /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
    @media (min-width: 768px) and (max-width: 1359px) {
      
    }
    
    /* Para dispositivos móviles con un ancho máximo de 767px */
    @media (max-width: 767px) {
    
      
      
    }