.ficohsa-premiere {
    .accordion.panelcontainer {
        .cmp-accordion__button {
            background-color: #EFF3FB;

            .cmp-accordion__title {
                color: #151D2C;
                font-weight: bold;
            }

            .cmp-accordion__button .cmp-accordion__icon:after {
                background-color: #475572;
            }
        }
    }
.cmp-title__text {
    color: #151D2C;
}

.gff-texto-blanco {
    .cmp-title{
        .cmp-title__text{
            color: #FFF;
        }
    }
}

.gff-cmp-btn-azul-filled-base .cmp-button {
    background: #0A335C;
}

.gff-filtro-independiente {
.gff-filtro-independiente__linea-divisora, .gff-filtro-independiente__items {
    background: #CFDBEE;
}
}
.cmp-gff-canales_digitales-section_two-bottom-button {
    background: #0A335C;
}
}
