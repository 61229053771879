.gff-cmp-cont-cards-productos {

.button {
  text-align: center;
  width: 202px;
  height: 48px;
  

  a.cmp-button {
    text-decoration: none;
    border-radius: 8px;
  }
}
.elemento-flex {
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        min-height: 392px;
        min-width: 316px;
        max-width: 350px;
        .cmp-container{    
          border-radius: 8px;
      
         
      
          .cmp-title{
            margin: 20px 33px 0 33px;
            .cmp-title__text{
              position: relative;
            }        
          }
      
          .cmp-text{
            margin: 30px 33px 0 33px;
            p{
              margin-block: 0.5rem;
              line-height: 1.13;
            }
          }
      
          .cmp-image {
            margin-top: 0;
          }
        }
        
      }

      .gff-cmp-title-seccion {
        
            .cmp-title {
              h3 {
                font-size: $dosxl;
                color: $Tonal-range-Neutral-neutral10;
                line-height: 34px;
                text-align: center;
              }

              
            }
         
      }
      .gff-cmp-cont-borde-gris {
        .cmp-container {
          border-radius: 16px;

          .title {
            .cmp-title {

              h6 {
                font-size: $sm;
                color: $Tonal-range-Neutral-neutral30;
                line-height: 24px;
                text-align: center;
              }
            }
          }

          .image {
            .cmp-image {
              img {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    
      
      /* Para pantallas de escritorio con un ancho mínimo de 1360px */
      @media (min-width: 1360px) {
        
      }
      
      
      /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
      @media (min-width: 768px) and (max-width: 1359px) {
        
      }
      
      /* Para dispositivos móviles con un ancho máximo de 767px */
      @media (max-width: 767px) {

        
        
      }

      @media (min-width: 767px) {
  
        .gff-contenedor-flex {
          & > .cmp-container {
              & > .aem-Grid{
          display: flex;
          justify-content: space-between;
          gap: 24px;
        }
          }
        
      }

      .gff-elemento-flex {
        flex: 1;
        img {
          max-width: 52px;
          margin: 0 auto;
        }
      }
    }
  }