
// Label superior con Imagen 

.gffaem-cmp-label-img-card{
    border-radius: 12px 0 12px;;
    position: absolute;
    padding: 0 !important;

    .cmp-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        height: 36px;

        .text {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .image {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 17.67px;

            img {
                width: 17px;
                
            }
        }
    }

   
    
        .cmp-text {
            margin: 0;
        }
    
        p {
            font-size: $xs;
            font-weight: bold;
            margin: 0;
            padding: 0 15px 0 5px;
        }
    
    
    .gff-cmp-txt-label-color-1 {
        background: $Tonal-range-Primary-primary70;
        p {
            color: $Tonal-range-Primary-primary20;
        }
    }
    
    .gff-cmp-txt-label-color-2 {
        background: #E2F3FF;
        p {
            color: #006688;
        }
    }
    
    .gff-cmp-txt-label-color-3 {
        background: #E7DEFF;
        p {
            color: #351782;
        }
    }
    
    .gff-cmp-txt-label-color-4 {
        background: #E8F5E9;
        p {
            color: #1B5E20;
        }
    }
}


// Label superior sin Imagen 

 .gff-cmp-txt-label-card {
    border-radius: 12px 0 12px;;
    position: absolute; 

    .cmp-text {
        margin: 0;
    }

    p {
        font-size: $xs;
        font-weight: bold;
        margin: 0;
        padding: 0 15px 0 15px;
    }
}

.gff-cmp-txt-label-color-1 {
    background: $Tonal-range-Primary-primary70;
    p {
        color: $Tonal-range-Primary-primary20;
    }
}

.gff-cmp-txt-label-color-2 {
    background: #E2F3FF;
    p {
        color: #006688;
    }
}

.gff-cmp-txt-label-color-3 {
    background: #E7DEFF;
    p {
        color: #351782;
    }
}

.gff-cmp-txt-label-color-4 {
    background: #E8F5E9;
    p {
        color: #1B5E20;
    }
}


// Label inferior con Imagen 

.gffaem-cmp-label-img-card-abajo {
    border-radius: 12px 0 0 0;
    position: absolute;
    top: -36px;
    right: 0;
    padding: 0 !important;

    .cmp-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        height: 36px;

        .text {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .image {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 17.67px;

            img {
                width: 17px;
                
            }
        }
    }

   
    
        .cmp-text {
            margin: 0;
        }
    
        p {
            font-size: $xs;
            font-weight: bold;
            margin: 0;
            padding: 0 15px 0 5px;
        }
    
    
    .gff-cmp-txt-label-color-1 {
        background: $Tonal-range-Primary-primary70;
        p {
            color: $Tonal-range-Primary-primary20;
        }
    }
    
    .gff-cmp-txt-label-color-2 {
        background: #E2F3FF;
        p {
            color: #006688;
        }
    }
    
    .gff-cmp-txt-label-color-3 {
        background: #E7DEFF;
        p {
            color: #351782;
        }
    }
    
    .gff-cmp-txt-label-color-4 {
        background: #E8F5E9;
        p {
            color: #1B5E20;
        }
    }
}

// Label inferior sin Imagen 


.gff-cmp-txt-label-inf-card {
    border-radius: 12px 0 0 0;
    position: absolute;
    top: -28px;
    right: 0;

    .cmp-text {
        margin: 0;
    }

    p {
        font-size: $xs;
        font-weight: bold;
        margin: 0;
        padding: 0 15px 0 15px;
    }
}

.gff-cmp-txt-label-color-1 {
    background: $Tonal-range-Primary-primary70;
    p {
        color: $Tonal-range-Primary-primary20;
    }
}

.gff-cmp-txt-label-color-2 {
    background: #E2F3FF;
    p {
        color: #006688;
    }
}

.gff-cmp-txt-label-color-3 {
    background: #E7DEFF;
    p {
        color: #351782;
    }
}

.gff-cmp-txt-label-color-4 {
    background: #E8F5E9;
    p {
        color: #1B5E20;
    }
}


.gff-bloque-cards{
    .gff-bloque_cards{
        .gff-bloque_cards-card{
            .gff-bloque_cards-card_tag{
                font-size: $xxs;
            }

            .gff-bloque_cards-card_content{
                p{

                    line-height: 24px;                    
                    letter-spacing: -0.5px;

                    b{
                        font-size: $md;
                        line-height: 26px;
                    }

                    &:first-child{
                        margin-bottom: 16px;
                    }
                }
            }
            .gff-bloque_cards-card_buttons{
                .gff-bloque_cards-card_buttons-two{
                    height: 46px;
                }
            }
        }

        &:first-child {
            .gff-bloque_cards-card{
                .gff-bloque_cards-card_tag{
                    margin-bottom: 16px;
                }
        }
    }
}
}
