.gff-cmp-cont-faq {
    .gff-cmp-title-seccion {
        .cmp-title {
            margin-bottom: 0;

            h3 {
                font-size: $dosxl;
                line-height: 34px;
                color: $Tonal-range-Neutral-neutral10;
            }
        }
    }

    .accordion {
        .cmp-accordion {
            .cmp-accordion__item {
                margin-bottom: 16px;

                .cmp-accordion__header {
                    .cmp-accordion__button {
                        border-radius: 8px;
                        border: none;
                        height: 62px;

                        &.cmp-accordion__button--expanded {
                            border-radius: 8px 8px 0 0;
                        }
                    }
                }

                .cmp-accordion__panel--expanded {
                    background-color: #fff;
                    border-radius: 0 0 8px 8px;
                    
                }
            }
        }
    }

    .gff-cmp-cont-faq {
        .cmp-container {
            .aem-Grid {
                display: flex;
                flex-direction: row;
                justify-content: center;

                &>.text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 16px;

                    .cmp-text {
                        p {
                            font-size: $sm;
                            color: $Tonal-range-Neutral-neutral30;
                            margin-bottom: 8px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}