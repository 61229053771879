
.cmp-gff-banner_disfruta {
    
    margin: 0 auto;

    .cmp-gff-banner_disfruta-content-title{
        font-size: $dosxl;
        color: $Tonal-range-Primary-primary30;
        line-height: 34px;
        letter-spacing: -1px;
        font-weight: 800;
      
    }
    .cmp-gff-banner_disfruta-content-description{
        color: $Tonal-range-Primary-primary30;
        font-weight: 400;
        line-height: 22.4px;
        font-size: $sm;
    }

    .cmp-gff-banner_disfruta-content-tagline {
        color: $Tonal-range-Neutral-neutral30;
        font-weight: 400;
        line-height: 18px;
    }

    .cmp-gff-banner_disfruta-buttons{
        .cmp-gff-banner_disfruta-buttons_button-one {
            background-color: $Tonal-range-Primary-primary30;
            width: 189px;
        }
    }

}

.gff-banner_disfruta_un-boton {
    .cmp-gff-banner_disfruta {
        .cmp-gff-banner_disfruta-content {
            .cmp-gff-banner_disfruta-buttons {
                .cmp-gff-banner_disfruta-buttons_button-two {
                    display: none;
                }
            }
        }
    }
}

.gff-banner_disfruta_borde-round {
    .cmp-gff-banner_disfruta {
        border-radius: 16px;
    }
}

@media only screen and (max-width: 768px) {
.cmp-gff-banner_disfruta {
    height: auto !important;
}
}
