.gff-cont-banner-hero-product {
    max-width: 1360px;
    margin: 0 auto;
}




/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {

.cmp-gff-banner_hero {

    height: 268px;
    margin: 0 auto;
    background-size: cover !important;
    padding: 0 !important;

    .cmp-gff-banner_hero-content-tagline {
        text-transform: uppercase;
    }

    .cmp-gff-banner_hero-content {
        max-width: 1120px;
        padding: 70px 0;
        width: 100%;
        margin: 0 auto;
    }
}
}