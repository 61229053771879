// The fonts were generated using fontpie (https://github.com/pixel-point/fontpie),
// a tool that converts font files into web-compatible formats and generates the necessary CSS code.

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
    font-family: 'defaultFont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./resources/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'defaultFont Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 104.47%;
    descent-override: 28.98%;
    line-gap-override: 0.00%;
    size-adjust: 94.19%;
  }

  @font-face {
    font-family: 'defaultFont';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./resources/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2');
 }
  

@font-face {
    font-family: 'titleFont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./resources/fonts/SourceSerifPro/SourceSerifPro-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'titleFont Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 90.75%;
    descent-override: 33.12%;
    line-gap-override: 0.00%;
    size-adjust: 101.16%;
  }
  