.gff-bloque-cards{
    background-color: transparent !important;
    
    .gff-bloque_cards {
      width: 1120px;
        .gff-bloque_cards-card {
          p{
            color: #384868;
            b{
              color: #151D2C;

            }
          }
            &:nth-child(2),
            &:nth-child(3) {
              .gff-bloque_cards-card_img {
                img {
                    object-fit: cover;
                };
              }
              
            }
          }
    }
}