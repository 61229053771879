.gff-cmp-cont-noticias{
    .cmp-container{
        .aem-Grid{
            
            .gff-cmp-cont-descargas-boton{
                width: 154px;
                margin: 0 auto;

                .cmp-container{
                    .aem-Grid{
                        width: 80%;
                        border: none;
                        background-color: $Tonal-range-Primary-primary30;
                        display: block;
                        padding: 8px 16px;
                        display: flex;
                        justify-content: end;

                        .text{
                            width: 80%;
                            .cmp-text{
                                p{
                                    a{
                                    color: $Tonal-range-Primary-primary95;
                                    font-weight: 700;
                                    line-height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
    
}