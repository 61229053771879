.gff-cmp-cont-tabs-custom {

    

    .gff-root-body {
        /* max-width: 1360px;
        margin: 0 auto; */
      }
      
      .gff-cmp-cont-body {
        &>.cmp-container {
          max-width: $body-desktop;
          margin: 0 auto;
      }
      }
      
      .gff-cmp-cont-servicios {
        background-color: $Tonal-range-Info-info100;
        border-radius: 8px;
        
      
        .cmp-container {
          max-width: 530px;
      
          .aem-Grid {
            display: flex;
            padding: 16px;
            
            &>.image:first-child {
              display: flex;
          flex-direction: column;
              width: 24px;
      
              .cmp-image {
                margin: 0;
              }
            }
      
            &>.image:last-child {
              width: 7px;
              display: flex;
          flex-direction: column;
          justify-content: center;
          .cmp-image {
            margin: 0;
          }
            }

            .text {
                display: flex;
                flex-direction: column;
                height: auto;
                margin-left: 16px;

                p {
                    font-size: $sm;
                    color: $Tonal-range-Neutral-neutral30;
                    margin: 0;
                    line-height: 24px;
                    b {
                      font-size: $sm;
                      color: $Tonal-range-Neutral-neutral30;
                      font-weight: 700;
                    }
                  }
            }
      
            
          }
      
          
        }
      }
      
      .gff-cmp-cont-borde-gris {
        .cmp-container {
          border: $Tonal-range-Neutral-neutral80 1px solid;

          ul li {
            margin-bottom: 8px;

          }
          p {

            font-size: $sm;
            color: $Tonal-range-Neutral-neutral30;
            line-height: 24px;

            b {
                font-size: $lg;
                color: $Tonal-range-Primary-primary30;
                font-weight: 800;
            }
          }
          
        }
      }
      
      
      /* Para pantallas de escritorio con un ancho mínimo de 1360px */
      @media (min-width: 1360px) {
        
      .gffaem-cmp-cont-3-col {
        &>.cmp-container {
          max-width: $body-desktop;
          margin: 0 auto;
          &>.aem-Grid{
            &::before {
              content: none;
            }
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* Tres columnas de tamaño igual */
            gap: 57px; /* Espacio entre columnas y filas */
      
            .gff-cmp-cont-borde-gris {
      
              width: 352px;
      
              .cmp-container {
                padding: 16px;
                border-radius: 16px;
              }
              
            }
        
          }
      
        }
      }
      }
      
      .gffaem-cmp-cont-2-col {
        &>.cmp-container {
          max-width: $body-desktop;
          margin: 0 auto;
          &>.aem-Grid{
            &::before {
              content: none;
            }
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Tres columnas de tamaño igual */
            gap: 16px;
           }
      
      
          .gff-cmp-cont-servicios{
      
            .cmp-container {
      
              max-width: none;
              margin: 0;
              border-radius: 16px;
              height: auto;
              display: flex;
              flex-direction: column;
              padding: 20px;
      
      
              &>.aem-Grid {
                display: block;        
                padding: 0;
      
                &>.image:first-child {
                  width: 26.67px;
          
                  .cmp-image {
                    margin: 0;
                  }
                }  
                
              }
            }
          }
      
        }
      }
      
      
      /* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
      @media (min-width: 768px) and (max-width: 1359px) {
        .gffaem-cmp-cont-3-col {
          .cmp-container {
            .aem-Grid{
        
              &::before {
                content: none;
              }
              flex-wrap: wrap;
              justify-content: center;
              gap: 35px;
              display: flex;
          
            }
        
          }
        }
      
        .gffaem-cmp-cont-2-col {
          .cmp-container {
            .aem-Grid{
        
              &::before {
                content: none;
              }
              flex-wrap: wrap;
              justify-content: center;
              gap: 35px;
              display: flex;
          
            }
        
          }
        }
        .gff-cmp-cont-body {
          max-width: 768px;
          margin: 0 !important;
          padding: 0 12px;
        }
      
        .gff-cmp-cont-servicios {
          background-color: $Tonal-range-Info-info100;
          border-radius: 8px;
          width: 100% !important;
          
        
          .cmp-container {
      
            max-width: 704px;
        
            .aem-Grid {
              display: flex;
              padding: 16px;
              width: 100%;
              
              &>.image:first-child {
                display: flex;
            flex-direction: column;
            justify-content: center;
                width: 24px;
        
                .cmp-image {
                  margin: 0;
                }
              }
        
              &>.image:last-child {
                width: 7px;
                display: flex;
            flex-direction: column;
            justify-content: center;
            .cmp-image {
              margin: 0;
            }
              }
        
              p {
                font-size: $xs;
                color: $Tonal-range-Neutral-neutral10;
                margin: 0;
                line-height: 20px;
                b {
                  font-size: 18px;
                  color: $Tonal-range-Primary-primary30;
                }
              }
      
              .text {
                max-width: 666px;
                margin-left: 10px;
              }
            }
        
            
          }
        }
      }
      
      /* Para dispositivos móviles con un ancho máximo de 767px */
      @media (max-width: 767px) {

        
        .gffaem-cmp-cont-3-col {
          .cmp-container {
            .aem-Grid{
        
              &::before {
                content: none;
              }
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 16px;
              display: flex;
          
            }
        
          }
        }
      
        .gffaem-cmp-cont-2-col {
          &.gff-cmp-cont-features {
            .cmp-container{
              .aem-Grid {
                .gff-cmp-cont-servicios{
                  .cmp-container{
                    height: auto !important;

                    .aem-Grid{
                      height: auto !important;
                    }
                  }
                }
              }
            }
          }
          .cmp-container {
            .aem-Grid{
        
              &::before {
                content: none;
              }
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 16px;
              display: flex;
          
            }
        
          }
        }
      
        .gff-cmp-cont-body {
          max-width: 328px;
          margin: 0 !important;
          
        }
      
        .gff-cmp-cont-servicios {
          background-color: $Tonal-range-Info-info100;
          border-radius: 8px;
          width: 100% !important;
          
        
          .cmp-container {
        
            .aem-Grid {
              display: flex;
              padding: 16px;
              width: 100%;
              
              &>.image:first-child {
                display: flex;
            flex-direction: column;
                width: 24px;
        
                .cmp-image {
                  margin: 0;
                }
              }
        
              &>.image:last-child {
                width: 7px;
                display: flex;
            flex-direction: column;
            justify-content: center;
            .cmp-image {
              margin: 0;
            }
              }
        
              p {
                font-size: $xs;
                color: $Tonal-range-Neutral-neutral10;
                margin: 0;
                line-height: 20px;
                b {
                  font-size: 18px;
                  color: $Tonal-range-Primary-primary30;
                }
              }
      
              .text {
                max-width: 210px;
                margin-left: 10px;
              }
            }
        
            
          }
        }
        
      }

    
}