.gff-cmp-title-seccion {

  &.gff-cmp-title-seccion-azul{
    h4{
      color: $Tonal-range-Primary-primary30;
      font-size: $dosxl;
      font-weight: 600;
      letter-spacing: -1px;
      line-height: 34px;
    }
  }
  h4 {
    letter-spacing: -1px;
  }
}

@media (max-width: 767px) {
  .gff-cmp-title-seccion {
    h4 {
      font-size: 22px !important;
      line-height: 30px !important;
      letter-spacing: -1px !important;
      margin: 0 16px !important;
    }
  }

  .gff-cmp-title-seccion-left-mobile {
    h4 {
      text-align: left !important;
    }
  }  
}

