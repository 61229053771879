.gffaem-cmp-cont-relacionado {
    background: $color-background;
    border-radius: 24px;
    overflow: hidden;
    max-width: 336px;
    filter: drop-shadow(0px 1px 3px #00000070);

    .cmp-image {
        margin-top: 0;
    }

    & > .cmp-container > .container{
        padding: 16px;

    }

    .gffaem-cmp-cuerpo-card {
        position: relative;
    }

    .gff-cmp-txt-body-card {
        height: 55px;
        p {
            color: $Tonal-range-Neutral-neutral30;
            font-size: $sm;    
            line-height: 24px;    
        }

    }

    .gff-cmp-title-title-card {

        h6 {
            font-size: $md;
            font-weight: bold;
            font-family: $font-family;
            text-align: center;
        }
        
    }
}



.gffaem-cmp-cont-prensa.gffaem-card-xl{
    max-width: 636px;
}
.gffaem-cmp-cont-prensa.gffaem-card-s{
    max-width: 286px;
}

// CARDS HORIZONTALES

.gffaem-card-horizontal{
    max-width: 928px;
    & > .cmp-container {
        display: inline-flex;
       
    }
}

.gffaem-card-horizontal.gffaem-card-s{
    max-width: 864px;
}

.gffaem-card-horizontal {
    .gffaem-cmp-cuerpo-card {
        .cmp-container{
            display: inline-block;
            text-align: left;
        }
    }

    .gffaem-cmp-cont-button-cont {
        .cmp-container {
            display: flex;
            gap: 16px;
        }
    }

    .gff-cmp-txt-tagline-card {
        .cmp-text {
            text-align: left;
        }
    }
}