.xf-content-height {
    margin: 0 !important;
}

.gff-texto-blanco {
    color: #fff !important;
    
    p {
        color: #fff !important;
    }
}

.gff-cmp-title-seccion {
    h4 {
        font-size: $dosxl;
        font-weight: 600;
        color: $Tonal-range-Neutral-neutral10;
        line-height: 28px;
    }
    h5 {
        font-size: $xxs;
        font-weight: 200;
        color: $Tonal-range-Neutral-neutral30;
        text-transform: uppercase;
    }

}

.gff-centrar-textos {

    .cmp-title {

        .cmp-title__text {
            text-align: center;
        }
    }

    .cmp-text {
        text-align: center;
    } 
}

.gff-hero-cont {
    

    .gff-custom__hero {
        
        .gff-custom__hero-image {

            img {
                max-width: 100%;
            }
            
        }

    }
}

.gff-header__top-bar-options-container, .gff-header-container {
    max-width: 1120px !important;
}

.container{

    div#scoredropdown {
        
        border-radius: 8px;
        
        .gff-iframe {
            padding: 16px;
    
            
        }
    }
    
}

@media only screen and (max-width: 950px) {
    .gff-indicadores-divisas__section-right {
        
        width: 100% !important;
    }
}

ul.gff-header-submenu__content-items li:hover {
    background-color: #1C202814;
}

ul.gff-header-submenu__content-items li:hover {
    padding: 0;
    margin-bottom: 8px;
}

.gff-header__icon-nav-item {
    width: 20px;
}

//  INDICADORES

.gff-indicadores-divisas{
    .gff-indicadores-divisas__component{
        .gff-indicadores-divisas__container{
            .gff-indicadores-divisas__title{
                .gff-indicadores-divisas__title-icon{

                }
                p{
                    font-size: $dosxl;
                    color: $Tonal-range-Primary-primary30;
                    font-weight: 600;
                    line-height: 34px;
                    letter-spacing: -1px;
                    span{
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 2px;

                    }
                }
            }
            .gff-indicadores-divisas__section-right{
                .gff-indicadores-divisas__section{
                    .gff-indicadores-divisas__divisa{
                        .gff-indicadores-divisas__divisa-uno{
                            &.gff-indicadores-divisas__divisa-activa{
                                img{

                                }
                                p{

                                }
                            }
                        }
                        .gff-indicadores-divisas__divisa-dos{
                            img{

                            }
                            p{

                            }
                        }
                    }
                    .gff-indicadores-divisas__divisa-activa{
                        box-shadow: 0 0 3px #0000002E;
                        border-radius: 8px;
                    }
                }
                .gff-indicadores-divisas__content{
                    .gff-indicadores-divisas__values{
                        justify-content: start;
                        gap: 72px;
                        .gff-indicadores-divisas__buys{

                        }
                        .gff-indicadores-divisas__sale{

                        }

                        .gff-indicadores-divisas__buys-value, .gff-indicadores-divisas__sale-value {
                            color: $Tonal-range-Primary-primary30;
                            font-weight: 800;
                            font-size: 32px;
                            line-height: 38px;
                            letter-spacing: -2px;
                        }

                    }
                    .gff-indicadores-divisas__info{

                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        color: $Tonal-range-Neutral-neutral30;

                        a{
                            font-size: $xs;
                            font-weight: 600;
                            line-height: 18px;

                            img{
                                width: 15px;
                            }
                        }
                    }
                    

                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
   

    .gff-indicadores-divisas{
        .gff-indicadores-divisas__component{
            .gff-indicadores-divisas__container{
                .gff-indicadores-divisas__title{
                    .gff-indicadores-divisas__title-icon{
    
                    }
                    p{
                        font-size: $dosxl;
                        color: $Tonal-range-Primary-primary30;
                        font-weight: 600;
                        line-height: 34px;
                        letter-spacing: -1px;
                        span{
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 2px;
                            font-weight: 600
    
                        }
                    }
                }
                .gff-indicadores-divisas__section-right{
                    .gff-indicadores-divisas__section{
                        .gff-indicadores-divisas__divisa{
                            .gff-indicadores-divisas__divisa-uno{
                                &.gff-indicadores-divisas__divisa-activa{
                                    img{
    
                                    }
                                    p{
    
                                    }
                                }
                            }
                            .gff-indicadores-divisas__divisa-dos{
                                img{
    
                                }
                                p{
    
                                }
                            }
                        }
                        .gff-indicadores-divisas__divisa-activa{
                            box-shadow: 0 0 3px #0000002E;
                            border-radius: 8px;
                        }
                    }
                    .gff-indicadores-divisas__content{
                        .gff-indicadores-divisas__values{
                            justify-content: start;
                            gap: 60px;
                            .gff-indicadores-divisas__buys{
    
                            }
                            .gff-indicadores-divisas__sale{
    
                            }
    
                            .gff-indicadores-divisas__buys-value, .gff-indicadores-divisas__sale-value {
                                color: $Tonal-range-Primary-primary30;
                                font-weight: 800;                                
                                font-size: 24px;
                                line-height: 28px;
                                letter-spacing: -1px;
                            }
    
                        }
                        .gff-indicadores-divisas__info{
    
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19.6px;
                            color: $Tonal-range-Neutral-neutral30;
    
                            a{
                                font-size: $xs;
                                font-weight: 600;
                                line-height: 18px;
    
                                img{
                                    width: 15px;
                                }
                            }
                        }
                        
    
                    }
                }
            }
        }
    }
}