.gff-accordion-iframe {
    .gff-accordion {
        .gff-accordion__iframe-item{
            .gff-accordion__iframe-container{
                img{
                    width: 48px;
                    object-fit: contain;
                }
            }
        }
    }
}
.gff-cmp-cont-faq {
    .cmp-container{
        .aem-Grid {
            .accordion {
                h3.cmp-accordion__header{
                    .cmp-accordion__button{
                        span.cmp-accordion__title {
                            color: $Tonal-range-Neutral-neutral10;
                            font-weight: 600;
                        }
                        .cmp-accordion__icon {
                            &:after {
                                background-color: #475572;
                            }
                        }
                    }
                }
                .cmp-accordion__panel{
                    p{
                        font-size: $sm;
                        color: $Tonal-range-Neutral-neutral30;
                    }
                }
            }
        }
    }
}

/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
    .gff-cmp-cont-body {
        .cmp-container{
            .aem-Grid{
                .gff-accordion {
                    width: 100% !important;
                }
            }
        }
    }
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {
  .gff-accordion{
    width: 100% !important;
  }
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  
  
}